<template>
  <b-card-code title="جدول الاقسام" no-body>
    <b-card-body>
      <div class="d-flex justify-content-between flex-wrap">
        <b-form-group
          label="فلتر"
          label-cols-sm="2"
          label-align-sm="left"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="ابحث عن .."
            />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">
                الغاء الفلتر
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                v-b-modal.model-a-w
              >
                <feather-icon icon="FilePlusIcon" class="mr-50" />
                <span class="align-middle">اضافة قسم جديد</span>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>
      <b-form-row style="margin-top: 10px">
        <b-col v-show="search.types.length > 0" md="4">
          <b-form-group>
            <v-select
              v-model="search.type_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="ar_title"
              :options="search.types"
              placeholder="اختر نوع القسم"
            />
          </b-form-group>
        </b-col>
      </b-form-row>
    </b-card-body>
    <div class="demo-vertical-spacing">
      <b-progress v-if="precentage !== 0" :value="precentage" max="100" />
    </div>
    <b-table
      ref="table"
      striped
      hover
      responsive
      class="position-relative"
      :per-page="perPage"
      :items="items"
      :fields="fields"
    >
      <template #cell(action)="action">
        <b-dropdown
          variant="link"
          no-caret
          :right="$store.state.appConfig.isRTL"
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>
          <b-col md="4">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-info"
              v-b-modal.model-e-e
              @click="eEM(action.item.id)"
            >
              <feather-icon icon="UsersIcon" class="mr-50" />
              <span class="align-middle"></span>
            </b-button>
          </b-col>
          <b-col md="4">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-warning"
              v-b-modal.model-e-w
              @click="eRM(action.item)"
            >
              <feather-icon icon="EditIcon" class="mr-50" />
              <span class="align-middle"></span>
            </b-button>
          </b-col>
          <b-col md="4">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-danger"
              v-b-modal.modal-center
              @click="dRM(action.item)"
            >
              <feather-icon icon="Trash2Icon" class="mr-50" />
              <span class="align-middle"></span>
            </b-button>
          </b-col>
        </b-dropdown>
      </template>
      <template #cell(logo)="logo">
        <b-avatar size="lg" :src="getImage(logo.item.logo)" />
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <b-form-group
        label="Per Page"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          @change="check()"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
        class="mb-0"
        @change="check()"
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </b-card-body>
    <b-modal
      id="model-e-w"
      ok-title="حفظ البيانات"
      centered
      size="lg"
      title="تعديل بيانات القسم"
      @show="checkModel"
      @hidden="rEF"
      @ok="uM"
    >
      <b-form novalidate class="needs-validation">
        <b-form-row>
          <b-col v-if="teachers.length > 0" md="12">
            <b-form-group>
              <label for="basic-password"> مدير القسم</label>
              <v-select
                v-model="editform.manager_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="teachers"
                placeholder="مدير القسم"
              />
            </b-form-group>
          </b-col>
          <b-col v-if="teachers.length > 0" md="12">
            <b-form-group>
              <label for="basic-password"> مقرر او الرديف</label>
              <v-select
                v-model="editform.coordinator_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="teachers"
                placeholder="اختر مقرر او الرديف"
              />
            </b-form-group>
          </b-col>
          <b-col v-if="types.length > 0" md="12">
            <b-form-group>
              <label for="basic-password">نوع القسم</label>
              <v-select
                v-model="editform.type_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="ar_title"
                :options="types"
                placeholder="اختر نوع القسم"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <label for="input-valid1">العنوان بالعربي:</label>
            <b-form-input
              id="input-valid1"
              v-model="editform.ar_name"
              :state="editform.ar_name.length > 0"
              placeholder="العنوان بالعربي"
            />
          </b-col>
          <b-col md="12">
            <label for="input-valid1">العنوان بالانكليزي:</label>
            <b-form-input
              id="input-valid1"
              v-model="editform.en_name"
              :state="editform.en_name.length > 0"
              placeholder="العنوان بالانكليزي"
            />
          </b-col>
          <b-col md="12">
            <label for="input-valid1">ملف الوكو:</label>
            <b-form-file
            placeholder="اختر الملف او اسقطها هنا..."
            drop-placeholder="اختر الملف او اسقطها هنا..."
            v-model="editform.file"
              @change="selectFile"
            />
          </b-col>
        </b-form-row>
      </b-form>
    </b-modal>
    <b-modal
      id="model-e-e"
      ok-title="Accept"
      centered
      size="lg"
      title="Edit Employees"
      @ok="uE"
      @hidden="rEF"
    >
      <b-form novalidate class="needs-validation" style="margin-bottom: 280px">
        <b-form-row>
          <b-col md="12">
            <label for="input-valid1">employees:</label>
            <v-select
            v-model="edit.employees"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            multiple
            label="title"
            :options="employees"
          />
          </b-col>
        </b-form-row>
      </b-form>
    </b-modal>
    <b-modal
      id="model-a-w"
      ok-title="حفظ البيانات"
      centered
      size="lg"
      title="اضافة قسم جديد"
      @show="checkModel"
      @ok="aNM"
      @hidden="rF"
    >
      <b-form novalidate class="needs-validation">
        <b-form-row>
          <b-col v-if="types.length > 0" md="12">
            <b-form-group>
              <label for="basic-password">اختر نوع القسم</label>
              <v-select
                v-model="form.type_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="ar_title"
                :options="types"
                placeholder="اختر نوع القسم"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <label for="input-valid1">العنوان بالعربي:</label>
            <b-form-input
              id="input-valid1"
              v-model="form.ar_name"
              :state="form.ar_name.length > 0"
              placeholder="العنوان بالعربي"
            />
          </b-col>
          <b-col md="12">
            <label for="input-valid1">العنوان بالانكليزي:</label>
            <b-form-input
              id="input-valid1"
              v-model="form.en_name"
              :state="form.en_name.length > 0"
              placeholder="العنوان بالانكليزي"
            />
          </b-col>
          <b-col md="12">
            <label for="input-valid1">اختر ملف الوكو:</label>
            <b-form-file
              placeholder="اختر الملف او اسقطها هنا..."
              drop-placeholder="اختر الملف او اسقطها هنا..."
              ref="file"
              v-model="form.file"
              @change="selectFiles"
            />
          </b-col>
        </b-form-row>
      </b-form>
    </b-modal>

    <b-modal
      id="modal-center"
      centered
      title="Delete User"
      @ok="dM"
      ok-title="Accept"
    >
      <b-card-text>
        do you really want to delete this item " {{ this.editform.ar_name }} " ?
      </b-card-text>
    </b-modal>
  </b-card-code>
</template>

<script>
import vSelect from "vue-select";
import store from "@/store";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BTable,
  BAvatar,
  BModal,
  BDropdown,
  BDropdownItem,
  BFormCheckboxGroup,
  BForm,
  BFormRow,
  BCol,
  VBModal,
  BProgress,
  BBadge,
  BFormValidFeedback,
  BFormInvalidFeedback,
  BFormGroup,
  BFormCheckbox,
  BCardText,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCardBody,
  BFormFile,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { integer } from "vee-validate/dist/rules";
import { filesUrl } from "@/main.js";

export default {
  components: {
    BCardCode,
    BTable,
    BForm,
    BFormFile,
    vSelect,
    filesUrl,
    BAvatar,
    BBadge,
    BFormRow,
    BDropdown,
    BDropdownItem,
    BFormGroup,
    togglePasswordVisibility,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormCheckboxGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BModal,
    VBModal,
    Ripple,
    BProgress,
    BCol,
    BFormCheckbox,
    BCardText,
    BFormValidFeedback,
    BFormInvalidFeedback,
  },
  data() {
    return {
      precentage: 0,
      teacher_id: "",
      perPage: 3,
      pageOptions: [3, 5, 10, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      department: "",
      title: "",
      employees: [],
      edit: {
        employees: [],
        department_id: null,
        count:0
      },
      search: {
        types: [],
        type_id: "",
      },
      filter: null,
      types: [],
      filterOn: [],
      coordinator_id: "",
      manager_id: "",
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      lecture_id: "",
      departments: [],
      fields: [
        {
          key: "en_name",
          label: "العنوان بالعربي ",
        },
        {
          key: "ar_name",
          label: "العنوان بالانكليزي ",
        },
        {
          key: "manager.ar_name",
          label: "مدير القسم ",
        },
        {
          key: "coordinator.ar_name",
          label: "مقرر او الرديف ",
        },
        {
          key: "type.ar_title",
          label: "نوع القسم ",
        },
        {
          key: "logo",
          label: "الوكو ",
        },
        { key: "action", label: "الاجراءات" },
      ],
      items: [],
      form: {
        ar_name: "",
        en_name: "",
        file: "",
        type_id: null,
      },
      editform: {
        ar_name: "",
        en_name: "",
        file: "",
        coordinator_id: "",
        manager_id: "",
        type_id: null,
        id: null,
      },
      teachers: [],
    };
  },
  mixins: [togglePasswordVisibility],
  computed: {
    sortOptions() {
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  directives: {
    Ripple,
  },
  mounted() {
    this.ID(), this.gR(), this.gSM(), this.gSM1(), this.gSM2();
    this.totalRows = this.items.length;
  },
  watch: {
    "search.type_id": function (val) {
      this.gR();
    },
  },
  methods: {
    async gSM() {
      this.employees = [];
      await this.axios.get(`teachers-name`).then((res) => {
        this.employees = res.data.items;
      });
    },
    async gSM2(id) {
      this.edit.employees = [];
      await this.axios.get(`employees/${id}`).then((res) => {
        this.edit.employees = res.data.items;
      });
    },
    async gSM1() {
      this.types = [];
      if (isNaN(this.editform.type_id)) {
        this.editform.type_id = this.editform.type_id["id"];
      }
      await this.axios.get(`types?manage=ture`).then((res) => {
        this.types = [];
        this.search.types = res.data.items;
        this.types = res.data.items;
      });
    },
    async gSM2() {
      this.types = [];
      await this.axios.get(`teachers-name`).then((res) => {
        this.teachers = [];
        this.teachers = res.data.items;
      });
    },
    checkModel() {
      this.selected = [];
      if (this.editform.is_sustainabilty == 1) {
        this.selected.push("is_sustainabilty");
      }
      if (this.editform.is_energy == 1) {
        this.selected.push("is_energy");
      }
    },
    async uE() {
      this.edit.count = this.edit.employees.length
      await this.axios
        .post(`employees/${this.edit.id}`, this.edit,{
          onUploadProgress: progressEvent => {
            this.precentage =
              (progressEvent.loaded / progressEvent.total) * 100;
          }
        })
        .then((res) => {
          if (res.data.errors) {
            this.errorToast();
          } else {
            this.gR();
            this.makeToast(
              "success",
              "update Success",
              "Your update is done successfully"
            );
          }
          this.precentage=0
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "warning",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    async uM() {
      let data = new FormData();
      data.append("ar_name", this.editform.ar_name);
      data.append("en_name", this.editform.en_name);
      if (this.editform.manager_id) {
        data.append("manager_id", this.editform.manager_id["id"]);
      }
      if (this.editform.coordinator_id) {
        data.append("coordinator_id", this.editform.coordinator_id["id"]);
      }
      data.append("type_id", this.editform.type_id);
      if (this.editform.file != "undefined" && this.editform.file != "") {
        data.append("logo", this.editform.file);
      }
      await this.axios
        .post(`since/deparment/${this.editform.id}`, data, {
          onUploadProgress: (progressEvent) => {
            this.precentage =
              (progressEvent.loaded / progressEvent.total) * 100;
          },
        })
        .then((res) => {
          if (res.data.errors) {
            this.errorToast();
          } else {
            this.gR();
            this.makeToast(
              "success",
              "update Success",
              "Your update is done successfully"
            );
          }
          this.precentage = 0;
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "warning",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
      this.rEF();
    },
    async aNM() {
      console.log(this.form.type_id);
      let data = new FormData();
      data.append("ar_name", this.form.ar_name);
      data.append("en_name", this.form.en_name);

      if (isNaN(this.form.type_id)) {
        data.append("type_id", this.form.type_id["id"]);
      }
      if (this.form.file != "undefined" && this.form.file != "") {
        data.append("logo", this.form.file);
      }
      await this.axios
        .post(`since/deparment`, data, {
          onUploadProgress: (progressEvent) => {
            this.precentage =
              (progressEvent.loaded / progressEvent.total) * 100;
          },
        })
        .then((res) => {
          if (res.data.errors) {
            this.errorToast();
          } else {
            this.gR();
            this.makeToast(
              "success",
              "update Success",
              "Your update is done successfully"
            );
          }
          this.precentage = 0;
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "warning",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
      this.rF();
    },
    rF() {
      (this.form.ar_name = ""),
        (this.form.en_name = ""),
        (this.form.type_id = ""),
        (this.form.file = "");
    },
    rEF() {
      (this.editform.ar_name = ""),
        (this.editform.en_name = ""),
        (this.editform.file = ""),
        (this.editform.type_id = ""),
        (this.editform.id = null);
    },
    async dM() {
      await this.axios
        .delete(`since/deparment/${this.editform.id}`)
        .then((res) => {
          if (res.data.errors) {
            this.errorToast();
          } else {
            this.gR();
            this.makeToast(
              "success",
              "Delete Success",
              "Your item is deleted successfully"
            );
          }
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "warning",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    errorToast() {
      this.makeToast(
        "danger",
        "update failed",
        "some thing want wrong, i`m sorry :("
      );
    },
    eRM(item) {
      this.editform.ar_name = item.ar_name;
      this.editform.en_name = item.en_name;
      this.editform.type_id = item.type_id;
      this.editform.manager_id = item.manager_id;
      this.editform.coordinator_id = item.coordinator_id;
      this.editform.file = item.logo;
      this.editform.id = item.id;
    },
    async eEM(id) {
      this.edit.id = id
      this.edit.employees = [];
      await this.axios.get(`employees/${id}`).then((res) => {
        console.log(res)
        this.edit.employees = res.data.items;
      });
    },
    dRM(item) {
      this.editform.ar_name = item.ar_name;
      this.editform.id = item.id;
    },
    check() {
      setTimeout(() => this.gR(), 1000);
    },
    ID() {
      this.teacher_id = localStorage.getItem("NisourTeacherId");
    },
    async gR() {
      await this.axios
        .get(
          `since/deparment?take=${this.perPage}&skip=${
            this.currentPage - 1
          }&type=${this.search.type_id.id}`
        )
        .then((res) => {
          console.log(res);
          this.items = [];
          this.totalRows = res.data.totalCount;
          this.items = res.data.items;
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "danger",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    makeToast(variant = null, title = null, body = null) {
      this.$bvToast.toast(body, {
        title: title,
        variant,
        solid: false,
      });
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    selectFiles(e) {
      this.form.file = "";
      console.log(e.target.files);
      console.log(e.target.files[0]);
      this.form.file = e.target.files[0];
      console.log(this.form.file);
    },
    selectFile(e) {
      this.editform.file = "";
      console.log(e.target.files);
      console.log(e.target.files[0]);
      this.editform.file = e.target.files[0];
      console.log(this.editform.file);
    },
    getImage(path) {
      return `${filesUrl}/${path}`;
    },
  },
};
</script>
